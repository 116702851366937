<template>
  <div>
    <home-menu />
    <main-banner />
    <raffles-filters @filterByCat="category = $event" />
    <b-container>
      <raffles-grid :category="category" />
      <last-winners />
    </b-container>
    <about-us class="d-none d-md-block" />
    <main-footer class="d-none d-md-block" />
  </div>
</template>

<script>
import {
  BContainer,
} from 'bootstrap-vue'
import { mapActions, mapMutations } from 'vuex'
import MainBanner from '@/@core/components/homeMainBanner/HomeMainBanner.vue'
import HomeMenu from '@/views/home/HomeMenu.vue'
import RafflesFilters from '@/@core/components/raffles/RafflesFilters.vue'
import RafflesGrid from '@/@core/components/raffles/RafflesGrid.vue'
import LastWinners from '@/@core/components/raffles/HomeMainWinners.vue'
import AboutUs from '@/@core/components/AboutUs.vue'
import MainFooter from '@/@core/components/MainFooter.vue'

export default {
  components: {
    BContainer,
    MainBanner,
    HomeMenu,
    RafflesFilters,
    RafflesGrid,
    LastWinners,
    MainFooter,
    AboutUs,
  },
  data() {
    return {
      category: null,
    }
  },
  beforeMount() {
    this.getClientRaffles({
      meta: {
        pagination: {
          all: true,
        },
      },
    })
    this.getClientRaffles({
      by_is_featured: true,
      meta: {
        pagination: {
          all: true,
        },
      },
    })
    this.getRafflesWinners({
      meta: {
        pagination: {
          all: true,
        },
      },
    })
  },
  destroyed() {
    this.RESET_RAFFLES()
  },
  methods: {
    ...mapActions('raffles', ['getClientRaffles', 'getRafflesWinners']),
    ...mapMutations('raffles', ['RESET_RAFFLES']),
  },
}
</script>

<style lang="scss">
</style>
