<template>
  <b-row class="main-banner">
    <b-col class="d-none d-sm-block">
      <slides />
    </b-col>
    <b-col class="d-block d-sm-none m-0 p-0">
      <slides-mobile />
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Slides from '@core/components/homeMainBanner/BannerSlides.vue'
import SlidesMobile from '@core/components/homeMainBanner/BannerSlidesMobile.vue'

export default {
  components: {
    Slides,
    SlidesMobile,
    BRow,
    BCol,
  },
}
</script>

<style lang="scss" scoped>
.main-banner {
  background: url('../../../assets/images/HomeMainBanner_background2.png');
  background-size: cover;
  animation: gradientAnimation 10s linear infinite alternate;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 50% 80%;
    }
  }
}
</style>
