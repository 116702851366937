<template>
  <b-row class="py-4">
    <b-col class="d-flex flex-column align-items-center justify-content-center">
      <div>
        <p class="mb-0">
          SORTEOS
        </p>
      </div>
      <p
        class="display-4 mb-1 text-center subtitle-filters d-sm-block font-weight-bold"
      >
        Edición Limitada
      </p>
      <div>
        <b-button
          v-model="cat"
          size="sm"
          class="mx-25"
          :variant="cat === null ? 'info' : null"
          pill
          @click="emitCat(null)"
        >
          Todo
        </b-button>
        <b-button
          v-for="category in nonRepeated(featureRaffles.map(x => {
            if(x.categories.length > 0) {
              return x.categories[0].name
            }
            return 'Sin categoría'
          }))"
          :key="category"
          v-model="cat"
          :variant="cat === category ? 'info' : null"
          size="sm"
          class="mx-25"
          pill
          @click="emitCat(category)"
        >
          {{ category }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  data() {
    return {
      cat: null,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters('raffles', ['featureRaffles']),
  },
  methods: {
    nonRepeated(array) {
      return _.uniq(array)
    },
    emitCat(category) {
      this.cat = category
      this.$emit('filterByCat', category)
    },
  },

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 992px) {
  .subtitle-filters {
    font-size: 2rem;
  }
}
</style>
