<template>
  <div class="main-card text-center px-2 px-lg-5 pt-2 pb-2">
    <b-img
      :src="require('@/assets/images/logo/FullLogo.png')"
      width="210"
      class="mb-0"
    />
    <div class="text-center mt-0 mb-0">
      <p class="text-white h3 mt-0 b-0 d-none d-sm-block">
        Últimos Ganadores
      </p>
      <p class="text-white h4 mt-0 mb-0 d-block d-sm-none">
        Últimos Ganadores
      </p>
    </div>
    <div>
      <slides />
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import Slides from '@core/components/raffles/LastWinnersSlides.vue'

export default {
  components: {
    Slides,
    BImg,
  },
}
</script>

<style lang="scss" scoped>
.main-card {
  padding: 1rem;
  border-radius: 25px;
  background: rgb(80,20,166);
  // background: linear-gradient(90deg, rgba(80,20,166,1) 0%, rgba(162,41,222,1) 50%, rgba(80,20,166,1) 100%);
  background-image: url('../../../assets/images/HomeMainBanner_background2.png');
  position: relative;
  background-size: cover;
  animation: gradientAnimation 10s linear infinite alternate;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 50% 80%;
    }
  }

  &::after{
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    background: url('../../../assets/images/SquareDots.png');
    background-size: cover;
    top: -100px;
    z-index: -1;
    right: -45px;

    @media screen and (max-width: 600px) {
      right: 5px;
    }
  }
  &::before{
    content: "";
    position: absolute;
    width: 240px;
    height: 240px;
    background: url('../../../assets/images/SquareDots.png');
    background-size: cover;
    bottom: 50px;
    left: 50px;
  }
}
</style>
