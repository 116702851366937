<template>
  <div>
    <swiper
      ref="mySwiper"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(data, n) in raffles.filter(x => x.is_bannered)"
        :key="`slide-${n}`"
        class="pt-5 pb-2"
      >
        <b-card
          overlay
          img-alt="card img"
          class="rounded-corners card-mobile mt-2 mx-2"
          :style="{ 'background-image' : `url(${data.optimized_images[0]})`}"
          body-class="overlay"
        >
          <div
            class="p-3 h-100 d-flex flex-column justify-content-between align-items-start"
          >
            <div>
              <b-badge
                v-if="data.categories.length > 0"
                pill
                variant="warning"
                class="badge-glow"
              >
                {{ data.categories[0].name }}
              </b-badge>
            </div>
            <div>
              <span class="raffle-title display-3">
                {{ data.name }}
              </span>
              <p class="text-white">
                {{ data.description }}
              </p>
              <b-button
                pill
                variant="gradient-primary"
                :to="{ name: 'raffle', params: { id: data.id } }"
              >
                <span v-if="new Date() > new Date(data.raffle_date) && !data.winner_user">Sorteo finalizado</span>
                <span v-else-if="data.winner_user">Ver el ganador</span>
                <span v-else>Comprar boletos</span>
              </b-button>
            </div>
          </div>
        </b-card>
      </swiper-slide>
      <div
        slot="button-next"
        class="swiper-button-next-custom swiper-button btn-icon rounded-circle"
        variant="primary"
      >
        <feather-icon
          icon="ChevronRightIcon"
          size="30"
        />
      </div>
      <div
        slot="button-prev"
        class="swiper-button-prev-custom swiper-button btn-icon rounded-circle"
        variant="primary"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="30"
        />
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BButton,
  BCard,
  BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BButton,
    BCard,
    BBadge,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        },
      },
    }
  },
  computed: {
    ...mapGetters('raffles', ['raffles']),
  },
}
</script>

<style lang="scss" scoped>
.swiper-button-prev-custom {
  right: 24rem;
}
.swiper-button-next-custom {
  right: 21rem;
}
.swiper-button {
  position: absolute;
  bottom: 10px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: rgba(white, 0.25);
}
.card {
  background: rgba(black, 0);
}
.raffle-title {
  color: white;
  text-align: center;
}
.card-mobile {

  height: 45vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.overlay {
  background: rgba(black, 0.30);
}
.rounded-corners {
  border-radius: 55px;
  overflow: hidden;
}
</style>
