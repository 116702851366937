<template>
  <div>
    <swiper
      ref="mySwiper"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="swiperOptions"
      class="position-relative center"
    >
      <swiper-slide
        v-for="(data, n) in rafflesWon"
        :key="`slide-${n}`"
        class="pt-5 pb-5"
      >
        <b-card
          img-top
          :img-src="data.thumbnails[0]"
          no-body
          class="card-profile pt-0 flex-column justify-content-between align-items-center"
        >

          <div>
            <div class="pt-2">
              <div class="ticket">
                {{ data.winner_ticket }}
              </div>
            </div>
          </div>
          <b-card-body class="mt-1 pt-0 px-0">
            <h3 class="wrap-name">
              {{ data.winner_user.name }}
            </h3>
            <h6 class="text-muted">
              {{ data.raffle_date | dateNtime }}
            </h6>
          </b-card-body>
        </b-card>
      </swiper-slide>
      <div
        slot="button-next"
        class="swiper-button-next-custom swiper-button btn-icon rounded-circle"
        variant="primary"
      >
        <feather-icon
          icon="ChevronRightIcon"
          size="30"
        />
      </div>
      <div
        slot="button-prev"
        class="swiper-button-prev-custom swiper-button btn-icon rounded-circle"
        variant="primary"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="30"
        />
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCard,
    BCardBody,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        },
        breakpoints: {
          1400: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          950: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          680: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          370: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          290: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('raffles', ['rafflesWon']),
  },
}
</script>

<style>
  .center div.swiper-wrapper{
    justify-content: center !important;
  }
</style>
<style lang="scss" scoped>

.wrap-name{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-button-prev-custom {
  right: 3.5rem;
}
.swiper-button-next-custom {
  right: 0;
}
.swiper-button {
  position: absolute;
  bottom: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: rgba(white, 0.25);
}
.profileImg {
  margin-top: -120px;
}
@import '~@core/scss/base/core/colors/palette-variables.scss';
.ticket {
    width: 150px;
    height: 70px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 30px;
    color: white;
    border-radius: 4px;
    position: relative;
    animation: pulse 8s ease infinite;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      position: absolute;
      right: -15px;
      top: calc(50% - 15px);
    }
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      position: absolute;
      left: -15px;
      top: calc(50% - 15px);
    }
    @keyframes pulse {
      50% {
        transform: scale(110%) rotate(-3deg);
      }
    }
}
</style>
