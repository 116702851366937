<template>
  <section class="my-5">
    <b-container>
      <b-row>
        <b-col
          sm="6"
          class="d-flex align-items-center justify-content-center"
        >
          <swiper
            ref="mySwiper"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="about-us-swiper"
          >
            <swiper-slide
              v-for="(data, n) in swiperData"
              :key="`slide-${n}`"
              class="pt-5 pb-2"
            >
              <b-img
                :src="data.img"
                class="swiper-img variable-size d-none d-sm-block"
              />
              <b-img
                :src="data.img"
                alt=""
                width="200"
                class="swiper-img d-block d-sm-none"
              />
            </swiper-slide>
          </swiper>
        </b-col>
        <b-col
          sm="6"
          class="d-flex justify-content-center flex-column"
        >
          <p class="font-weight-bolder">
            ¿Quienes somos?
          </p>
          <h1
            class="font-weight-bolder display-4 title-decoration d-none d-sm-block aboutus-title"
          >
            ¡Una comunidad que siempre gana!
          </h1>
          <h1
            class="font-weight-bolder display-6 title-decoration d-block d-sm-none"
          >
            ¡Una comunidad que siempre gana!
          </h1>
          <p class="font-weight-bolder">
            Participa y llévate miles de premios.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Quidem vitae aliquid omnis expedita suscipit eaque natus enim,
            aspernatur delectus, aliquam unde. Voluptate nihil necessitatibus,
            at vero tenetur perferendis sequi rem?
          </p>
          <div>
            <b-button
              variant="gradient-primary"
              pill
            >
              Leer más
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BButton,
  BContainer,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BImg,
    BContainer,
    BRow,
    BCol,
    Swiper,
    SwiperSlide,
    BButton,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        {
          title: 'Kit Gamer',
          subtitle: 'Comprar boletos',
          img: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
        },
        {
          title: 'Kit Gamer',
          subtitle: 'Comprar boletos',
          img: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
        },
      ],
      /* eslint-disable global-require */
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

$margin-size: 55px;
.about-us-swiper {
  width: 500px;
}
.swiper-img {
  margin: $margin-size;
  border-radius: 2rem;
  box-shadow: -20px -20px $primary;
  transition: .25s ease all;
  &:hover {
    box-shadow: -30px -30px $primary;
  }
}

section {
  background: rgb(9,9,121);
  background: linear-gradient(90deg, rgba(9,9,121,0) 20%, rgba(241,240,255,1) 20%, rgba(241,240,255,1) 100%);
}

.title-decoration {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 240px;
    height: 240px;
    background: url('../../assets/images/pages/auth-v1-top-bg.png');
    background-size: cover;
    top: -120px;
    right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .aboutus-title {
    font-size: 2rem;
  }
}
.variable-size {
  width:   400px;
}
@media screen and (max-width: 999px) {
  .variable-size {
    width:   300px;
  }
}
@media screen and (max-width: 780px) {
  .variable-size {
    width:   200px;
  }
}

</style>
