<template>
  <b-row class="match-height">
    <!-- Prodcuts -->
    <b-col
      v-for="raffle in featureRaffles.filter(currentRaffle => { if (category != null) { return currentRaffle.categories[0].name === category} else { return true }})"
      :key="raffle.id"
      cols="12"
      sm="6"
      md="4"
      lg="3"
    >
      <raffle-card :raffle="raffle" />
    </b-col>
    <b-col
      cols="12"
      class="pt-2 pb-4"
    >
      <div class="d-flex justify-content-center">
        <b-button
          :to=" {name: 'Sorteos' }"
          variant="gradient-primary"
          pill
          size="lg"
        >
          Ver Todos
        </b-button>
      </div>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import RaffleCard from '@/@core/components/raffles/RaffleCard.vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    RaffleCard,
  },
  props: {
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('raffles', ['featureRaffles', 'pagination']),
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce.scss";
.btn-cart {
  border-radius: 0 0 0.358rem 0.358rem;
}
</style>
