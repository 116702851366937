<template>
  <b-card
    class="ecommerce-card cursor-pointer card-bg"
    no-body
    @click="$router.push({name: 'raffle', params: { id: raffle.id } })"
  >
    <div class="item-img text-center">
      <b-badge
        v-if="raffle.categories[0]"
        class="raffle-category"
        variant="warning"
      >
        {{ raffle.categories[0].name }}
      </b-badge>
      <b-img
        :alt="`${raffle.name}-${raffle.id}`"
        width="100%"
        class="card-img-top img-mesures"
        :src="raffle.thumbnails[0]"
      />
    </div>

    <!-- Product Details -->
    <b-card-body>
      <h5 class="item-name mt-0 mb-0">
        <b-link
          class="text-body font-weight-bolder h3"
        >
          {{ raffle.name }}
        </b-link>
      </h5>
      <b-card-text class="item-description text-dark">
        {{ raffle.description }}
      </b-card-text>
      <div class="item-wrapper d-flex justify-content-between">
        <div class="item-date text-small">
          <div class="font-weight-bolder">
            Fecha del sorteo
          </div>
          {{ raffle.raffle_date | dateNtime }}
        </div>
        <div>
          <h6 class="item-tickets raffle-tickets text-small">
            <div>
              Boletos
            </div>
            {{ raffle.available_tickets }} / {{ raffle.number_of_tickets }}
          </h6>
        </div>
      </div>
    </b-card-body>

    <!-- Product Actions -->
    <div
      class="item-options text-center"
    >
      <b-button
        tag="a"
        class="btn-cart btn-gradient"
        block
        variant="gradient-primary"
        :to="{ name: 'raffle', params: { id: raffle.id } }"
      >
        <span v-if="new Date() > new Date(raffle.raffle_date) && !raffle.winner_user">Sorteo finalizado</span>
        <span v-else-if="raffle.winner_user">Ver el ganador</span>
        <span v-else>Comprar</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BBadge,
  },
  props: {
    raffle: {
      type: Object,
      default: () => ({ return: {} }),
    },
  },
}
</script>

<style lang="scss" scoped>
.card-bg{
  background-color: #F1F0FF
}
.raffle-category {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.raffle-tickets{
  font-weight: 700;
  color: #707070;
}
.item-description {
  margin-top: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.btn-gradient {
  background: rgb(16,106,210);
  background: linear-gradient(90deg, rgba(16,106,210,1) 0%, rgba(97,70,216,1) 50%, rgba(162,41,222,1) 100%);
}
.text-small {
  font-size: 11px;
}

</style>
